import React from 'react';
import pdfIcon from 'images/icon/pdf-icon.svg';
import downloadIcon from 'images/icon/download-orange-ic.svg';
import { useTranslation } from 'react-i18next';
import Adikin from 'images/AboutUs/komisaris-adikin.png';
import Kevin from 'images/AboutUs/komisaris-kevin.png';
import Kohin from 'images/AboutUs/kohin.jpeg';

const dataCommiteAudit = [
  {
    name: 'Adikin Basirun',
    position: 'Committee',
    image: Adikin,
  },
  {
    name: 'Kevin Cahya',
    position: 'Member',
    image: Kevin,
  },
  {
    name: 'Kohin Djunaedi',
    position: 'Member',
    image: Kohin,
  },
];

const SectionAuditCommittee = () => {
  const { t } = useTranslation('about-us');

  return (
    <div className="min-h-[60vh] py-3">
      <div>
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('title komite-audit')}
          </h2>
        </div>
        <div className="py-5 px-3 grid grid-cols-2 md:grid-cols-4 gap-5 md:gap-10">
          {dataCommiteAudit?.map((item) => (
            <div>
              <img
                src={item.image}
                alt={item.name}
                className="w-full h-44 md:w-44 md:h-60 object-cover rounded-md"
              />
              <div className="mt-2 md:mt-3">
                <p className="text-sm md:text-base font-medium">{item.name}</p>
                <p className="font-[200] text-[13px] md:text-sm">
                  {t(`Audit ${item.position}`)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-5 md:mt-10">
        <div className="border-b py-3">
          <h2 className="text-base md:text-2xl font-medium">
            {t('piagam komite audit')}
          </h2>
        </div>
        <div
          className="flex justify-between py-3 md:py-5 px-0 md:px-3 cursor-pointer hover:underline"
          onClick={() =>
            window.open(
              'https://finfolk.ap-south-1.linodeobjects.com/002-PAI-MGU-II-2023 Piagam Komite Audit.pdf',
            )
          }
        >
          <div className="flex items-center gap-3">
            <img src={pdfIcon} alt="pdf-icon" className="w-4" />
            <p className="text-sm md:text-base font-[200]">
              {t('piagam komite audit')}
            </p>
          </div>
          <img
            src={downloadIcon}
            alt="icon-download"
            className="block md:hidden"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionAuditCommittee;
